<template>
  <div class="col-12 col-md-10 col-xl-9 mb-3">
    <div class="row" v-if="isNewBank">
      <div class="col-12 text-center mb-3">
        <Button color="gold" btnText="Use Existing Bank Account" @buttonClicked="useExistingBank" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <SelectAll
          v-model="value.bankName"
          id="bank"
          :options="bankOptions"
          placeholder="Select Bank"
          displayValue="bankName"
          saveValue="bankName"
          color="gold"
          :hasMore="true"
        />
      </div>
      <div class="col-md-6 mb-3">
        <TextField type="text" color="gold" placeholder="Account Holder Name" v-model="value.accountHolderName" id="name" />
      </div>
      <div class="col-md-6 mb-3">
        <TextField type="text" color="gold" placeholder="Branch Code" v-model="value.branchCode" id="branch" />
      </div>
      <div class="col-md-6 mb-3">
        <TextField type="text" color="gold" placeholder="Account Number" v-model="value.accountNumber" id="number" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue'))
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    isNewBank: {
      type: Boolean
    }
  },
  emits: ['update:modelValue', 'useExistingBank'],
  async beforeMount () {
    await this.fetchBankOptions()
  },
  computed: {
    ...mapGetters([
      'bankOptions'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    ...mapActions(['fetchBankOptions']),
    useExistingBank () {
      this.$emit('useExistingBank')
    }
  }
}
</script>
